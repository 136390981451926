import styled from "@emotion/styled";
import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import metaData from "../data/metaData.json";
import data from "../data/about.json";
import Typography from "../components/Typography";
import {
  container,
  lg_section_description,
  lg_section_title,
  wrapper,
} from "../assets/style/GeneralStyleComponent";
import { css } from "@emotion/react";
import { mdLayout } from "@variables/layout";
import { PADDING_7 } from "@variables/paddings";
import OurValueSection from "../components/about/OurValueSection";
import FeatureSection from "../components/about/FeatureSection";
import MissionVisionSection from "../components/about/MissionVisionSection";

const AboutPage = () => {
  return (
    <Layout>
      <SEO {...metaData.about} heroImage="Positive thinking-cuate.svg">
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "Xramile",
            "url": "https://xramile.com/",
            "logo": "https://xramile.com/logo.png",
            "image": "https://xramile.com/logo.png",
            "email": "info@xramile.com",
            "description": "Xramile is an interdisciplinary Europe-based company specialized in offering business and technology solutions for startup, small, and medium-level companies. We offer everything from agile marketing, branding, and technology solutions to tailored Business, human resources, and financial management services.",
            "address": "Ul. Grzybowska 12/14, lok. 22 Warszawa, Poland 00-132",
            "telephone": "+905418058542",
            "sameAs": [
              "https://www.snapchat.com/add/x-ramile",
              "https://twitter.com/xramile_com",
              "https://www.linkedin.com/company/xramile/",
              "https://www.instagram.com/xramile.business.solutions/"
            ],
            "areaServed": "Saudi Arabia"
          }
        `}</script>
      </SEO>
      <HeroSection>
        <Wrapper>
          <Typography
            as="h2"
            content={data.heroSection.title}
            css={hero_section_title_style}
          />
          <Typography
            as="p"
            content={data.heroSection.description}
            css={hero_section_description_style}
          />
        </Wrapper>
        <img
          loading="lazy"
          alt="Positive thinking-cuate"
          className="start-img"
          src="/Positive thinking-cuate.svg"
        />
        <img
          loading="lazy"
          alt="Man thinking-cuate"
          className="end-img"
          src="/Man thinking-cuate.svg"
        />
      </HeroSection>
      <FeatureSection data={data.features} />
      <MissionVisionSection data={data.missionVission} />
      <OurValueSection />
    </Layout>
  );
};

export default AboutPage;

const Wrapper = styled.div`
  ${wrapper}
`;
const hero_section_title_style = css`
  ${lg_section_title};
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
`;
const hero_section_description_style = css`
  ${lg_section_description}
  max-width: 75ch;
  margin: 0 auto;
  text-align: center;
`;

const HeroSection = styled.div`
  ${container}
  padding-top: ${PADDING_7};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0;
  padding-bottom: 0;
  min-height: 700px;
  height: 100vh;
  ${Wrapper} {
    grid-area: 3/1/2/7;
  }
  img {
    height: 100%;
    object-fit: scale-down;
    object-position: bottom;
  }

  .start-img {
    grid-area: 5/1/5/1;
  }
  .end-img {
    grid-area: 5/3/5/3;
    margin-right: 0;
    margin-left: auto;
    padding-bottom: 2px;
  }

  @media ${mdLayout} {
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(1, 1fr);
    margin: 0;

    .hero-sec-container {
      grid-template-rows: repeat(2, 1fr);
    }
  }
`;
