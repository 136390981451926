import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { fontFamily } from "../../assets/style/font-styles";
import {
  container,
  lg_section_title,
  row,
} from "../../assets/style/GeneralStyleComponent";
import {
  PADDING_15,
  PADDING_4,
  PADDING_5,
  PADDING_6,
} from "@variables/paddings";
import { FONTSIZE_14, FONTSIZE_5 } from "@variables/font-sizes";
import { lgLayout, mdLayout } from "@variables/layout";
import { LIENHEIGHT_4 } from "@variables/text";
import AppImage from "../AppImage";
import Typography from "../Typography";

export default function FeatureSection({ data }) {
  return (
    <Container>
      <Typography css={feature_title} as={"h2"} content={data.title} />

      <Row>
        <AppImage css={img_style} src="../../Group 2300.svg" alt={"test"} />
        <Content>
          {data.list.map((feature) => (
            <ContentItem key={`feature-${feature.index}`} index={feature.index}>
              {feature.content}
            </ContentItem>
          ))}
        </Content>
      </Row>
    </Container>
  );
}

const ContentItem = styled.li<{ index: string }>`
  font-family: ${fontFamily("Montserrat")};
  ${FONTSIZE_5}
  line-height: ${LIENHEIGHT_4};
  padding-bottom: ${PADDING_4};
  padding-left: ${PADDING_6};
  position: relative;
  &::before {
    content: "${(props) => props.index}";
    position: absolute;
    color: rgb(122, 122, 122);
    left: 0px;
    ${FONTSIZE_14}
    height: fit-content;
    top: 0px;
    line-height: normal;
    font-weight: 900;
  }
  @media ${mdLayout} {
    padding-left: ${PADDING_15};
  }
`;

const Content = styled.ul`
  width: 50%;
  list-style: none;
  @media ${lgLayout} {
    width: 100%;
  }
`;
const Container = styled.section`
  ${container}
`;

const Row = styled.div`
  ${row}
  margin: 0;
  width: 100%;
  justify-content: space-between;
`;

const feature_title = css`
  ${lg_section_title}
  padding-bottom: ${PADDING_4};
  width: 100%;
  max-width: 100%;
  letter-spacing: normal;
  text-align: center;
  @media ${lgLayout} {
    padding-top: ${PADDING_5};
  }
`;

const img_style = css`
  width: 45%;
  @media ${lgLayout} {
    display: none;
  }
`;
